import { Input, Typography, Checkbox } from "@material-ui/core"
import clsx from "clsx"
import CircleCloseButton from "Components/Forms/Controls/CircleCloseButton"
import { isNumber } from "Globals/GenericValidators"
import { sumArray, zipArray } from "Globals/Helpers"
import { useState, useEffect } from "react"
import { selectOriginalJobConfig, selectAreaIndex, selectArea, selectDoesAreaHaveRoomChanges, selectDoesAreaHaveServiceChanges, updateArea } from "Redux/genericSalesReducer"
import { useAppDispatch, useAppSelector } from "Redux/hooks"
import { UpdateQuotePriceProps } from "./ChangeOrderDialog"

export default function AreaSqftEditor({requotePrice}: UpdateQuotePriceProps) {
    const dispatch = useAppDispatch()
    const originalJobConfiguration = useAppSelector(selectOriginalJobConfig)
    const areaIndex = useAppSelector(selectAreaIndex)
    const area = useAppSelector(selectArea(areaIndex))

    const wereRoomChanges = useAppSelector(selectDoesAreaHaveRoomChanges(areaIndex))
    const wereServiceChanges = useAppSelector(selectDoesAreaHaveServiceChanges(areaIndex))

    const originalArea = (originalJobConfiguration?.areas.length ?? 0) > areaIndex && originalJobConfiguration?.areas[areaIndex].id === area.id
        ? originalJobConfiguration?.areas[areaIndex]
        : undefined;

    const areaSqft = sumArray(area.rooms.map(room => room.sqft))
    const originalSqft = sumArray(originalArea?.rooms?.map(room => room.sqft) ?? [-1])
    const [sqftInput, setSqftInput] = useState<string>(areaSqft.toString());

    const isChanges = +sqftInput !== originalSqft

    const showCancel = isChanges

    const isMaterialOnly = isChanges && !area.rooms.some(room => room.services.some(ser => ser.laborAmount === room.sqft))
    const canSetMaterialOnly = isChanges && !wereRoomChanges && !wereServiceChanges

    function updateAreaSqft(sqft: string | number) {
        const convertedSqft = +sqft;
        if (isNumber(convertedSqft as unknown as string) && convertedSqft > 0) {
            const useOriginal = convertedSqft === originalSqft && !wereRoomChanges && !wereServiceChanges

            // Update the sqfts of the rooms
            const updatedRooms = [...area.rooms.map((room, index) => {
                const updatedSqft = (useOriginal
                    ? originalArea?.rooms[index].sqft
                    : undefined)
                    ?? +((room.sqft / areaSqft) * convertedSqft).toFixed(2)

                return {
                    ...room,
                    sqft: updatedSqft,
                    services: [...room.services.map(service => {
                        if (service.laborAmount === room.sqft) {
                            return {
                                ...service,
                                laborAmount: updatedSqft,
                                materialAmount: (service.materialAmount === room.sqft) ? updatedSqft : service.materialAmount
                            }
                        }
                        else return service
                    })]
                }
            })]

            const roomSum = sumArray(updatedRooms.map(room => room.sqft))
            if (!useOriginal && roomSum !== convertedSqft) {

                const splitSqft = convertedSqft.toString().split(".")
                const maxDecimals = splitSqft.length > 1 ? splitSqft[1].length : 2
                const updatedSqft = +(updatedRooms[0].sqft + roomSum - convertedSqft).toFixed(maxDecimals)

                updatedRooms[0].services = [...updatedRooms[0].services.map(service => {
                    if (service.laborAmount === updatedRooms[0].sqft) {
                        return {
                            ...service,
                            laborAmount: updatedSqft,
                            materialAmount: (service.materialAmount === service.laborAmount) ? updatedSqft : service.materialAmount
                        }
                    }
                    else return service
                })]

                updatedRooms[0].sqft = updatedSqft
            }

            dispatch(updateArea(areaIndex, { ...area, rooms: updatedRooms })
            )

        }
        setSqftInput(sqft as string)

        //requotePrice();
    }

    function changeMaterialOnly() {
        if (originalArea === undefined) return;

        if (isMaterialOnly) {
            // Setting to not material only
            // Need to sync up services that should be the whole room with the current sqft of the room
            const updatedRooms = zipArray(originalArea.rooms, area.rooms).map(grp => {
                const oriRoom = grp[0]
                const room = grp[1]

                const updatedServices = zipArray(oriRoom.services, room.services).map(serGrp => {
                    const oriSer = serGrp[0]
                    const service = serGrp[1]

                    if (oriSer.laborAmount === oriRoom.sqft) {
                        return {
                            ...service,
                            laborAmount: room.sqft,
                            materialAmount: (oriSer.materialAmount === oriSer.laborAmount) ? room.sqft : oriSer.materialAmount
                        }
                    }
                    else return service
                })

                return { ...room, services: updatedServices }
            })

            dispatch(updateArea(areaIndex, { rooms: updatedRooms }))
        } else {
            // Setting to be material only
            // Need to remove all changes made to service labor amount (for the ones that have changed)
            const updatedRooms = zipArray(originalArea.rooms, area.rooms).map(grp => {
                const oriRoom = grp[0]
                const room = grp[1]

                const updatedServices = zipArray(oriRoom.services, room.services).map(serGrp => {
                    const oriSer = serGrp[0]
                    const service = serGrp[1]

                    if (service.laborAmount === room.sqft) {
                        return {
                            ...service,
                            laborAmount: oriSer.laborAmount,
                            materialAmount: (service.materialAmount === service.laborAmount) ? oriRoom.sqft : service.materialAmount
                        }
                    }
                    else return service
                })

                return { ...room, services: updatedServices }
            })

            dispatch(updateArea(areaIndex, { rooms: updatedRooms }))
        }

       // requotePrice();
    }


    useEffect(() => {
        setSqftInput(areaSqft.toString())
        // eslint-disable-next-line
    }, [setSqftInput, areaIndex])

    return (
        <>
            <div className="flex-row fill-width padding-bottom-xsm" style={{ alignItems: "center", justifyContent: "center" }}>
                <Input
                    value={sqftInput}
                    onChange={e => updateAreaSqft(e.currentTarget.value)}
                    style={{ width: "5rem" }}
                    endAdornment={
                        <CircleCloseButton
                            hidden={!showCancel}
                            onClick={() => updateAreaSqft(originalSqft)}
                            style={{ minWidth: "0", height: "1.3em", width: "1.3em", fontSize: "1.1em" }} />}
                />
                <Typography>Total Sqft</Typography>
                <Checkbox
                    className={clsx({ 'visibility-hidden': !canSetMaterialOnly })}
                    style={{ height: "2rem" }}
                    checked={isMaterialOnly}
                onChange={changeMaterialOnly} 
                />
                <Typography className={clsx({ 'visibility-hidden': !canSetMaterialOnly })}>Material Only</Typography>
            </div>
        </>
    )
}